import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import FormNeeded from '../webx/form-needed'
import WebbDividerMedium from '../webx/webb-divider-md'
import WebbDividerSmall from '../webx/webb-divider-sm'


import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'


export default function FormatsCreateCSModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [done, setDone] = useState(false)
  const [memo, setMemo] = useState('')
  const [memoType, setMemoType] = useState('')
  const [taxxon, setTaxxon] = useState([])
  const [media, setMedia] = useState({})
  const [format, setFormat] = useState([])
  const [data, setData] = useState({
    name: '',
    memo: '',
    format: '',
    sort: '',
    start: '',
    end: ''
  })

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val })
  }

  return (
    <>
      {/* info */}
      <div className='mx-3'>
        <h2 className='text-normal m-0'>{'New Credit Score Model'}</h2>
        <WebbDividerSmall />
      </div>

      

    </>
  )
}
