// routes
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../context/authcontext'

// views - main
import Main from '../views.xz/main-xz'

// views - home
import HomeUser from '../views.home/home-user'
import HomeTeam from '../views.home/home-team'

// views - assets
import Assets from '../views.assets/assets'
import AssetsCreate from '../views.assets/assets-create'
import AssetsViewCreator from '../views.assets/assets-view-creator'

// views - formats
import Formats from '../views.formats/formats'
import FormatsCreate from '../views.formats/formats-create'
import FormatsCreateCreditScore from '../views.formats/formats-create-cs'

import FormatsViewConsumer from '../views.formats/formats-view-consumer'

// views - transfers
import Transfers from '../views.transfers/transfers'

// views - network
import Network from '../views.network/network'
import NetworkViewCreator from '../views.network/network-view-creator'
import NetworkMembersAdd from '../views.network/network-add'

// views - network.team
import Team from '../views.network.team/team'
import TeamMemberCreate from '../views.network.team/team-create'

// views - user
import UserDetails from '../views.user/user-details'
import TeamDetails from '../views.user/team-details'

// views - onboard
import UserOnboardName from '../views.onboard/user-onboard-name'
import UserOnboardIdentiti from '../views.onboard/user-onboard-id'
import UserOnboardMinter from '../views.onboard/user-onboard-minter'
import UserOnboardHold from '../views.onboard/user-onboard-hold'

// views - auth
import AuthMailCode from '../views.auth/auth-mail-code'
import AuthNext from '../views.auth/auth-next'
import AuthSessionX from '../views.auth/auth-session-x'
import TransfersCreate from '../views.transfers/transfers-create'
import TransfersViewConsumer from '../views.transfers/transfers-view-consumer'

const routes = [
  { route: '/', content: <Main />, auth: false },

  // user
  { route: '/user/home', content: <HomeUser />, auth: true },
  { route: '/user/assets', content: <Assets />, auth: true },
  { route: '/user/transfers', content: <Transfers />, auth: true },

  // home
  { route: '/team/home', content: <HomeTeam />, auth: true },

  // network
  { route: '/team/network', content: <Network />, auth: true },
  { route: '/team/ux/:id', content: <NetworkViewCreator />, auth: true },

  // assets
  { route: '/team/assets', content: <Assets />, auth: true },
  { route: '/team/assets/create', content: <AssetsCreate />, auth: true },
  
  { route: '/team/assets/:id', content: <AssetsViewCreator />, auth: true },

  // formats
  { route: '/team/formats', content: <Formats />, auth: true },
  { route: '/team/formats/create', content: <FormatsCreate />, auth: true },
  { route: '/team/formats/create/cs', content: <FormatsCreateCreditScore />, auth: true },

  { route: '/team/formats/:id', content: <FormatsViewConsumer />, auth: true },

  // tokens

  // network
  { route: '/team/network', content: <Network />, auth: true },
  { route: '/team/network/add', content: <NetworkMembersAdd />, auth: true },

  // team
  { route: '/team/team', content: <Team />, auth: true },
  { route: '/team/members/create', content: <TeamMemberCreate />, auth: true },
  { route: '/team/members/remove', content: <TeamMemberCreate />, auth: true },

  // transfers
  { route: '/team/transfers', content: <Transfers />, auth: true },
  { route: '/team/transfers/create', content: <TransfersCreate />, auth: true },
  {
    route: '/team/transfers/:id',
    content: <TransfersViewConsumer />,
    auth: true
  },

  // transfers - funds
  { route: '/team/tx/:id', content: <AssetsViewCreator />, auth: true },

  // accounts

  // user
  { route: '/user/account', content: <UserDetails />, auth: true },
  { route: '/team/account', content: <TeamDetails />, auth: true },

  // documents

  // onboard
  { route: '/user/onboard', content: <UserOnboardName />, auth: true },
  { route: '/user/onboard/id', content: <UserOnboardIdentiti />, auth: true },
  { route: '/user/onboard/minter', content: <UserOnboardMinter />, auth: true },
  { route: '/user/onboard/hold', content: <UserOnboardHold />, auth: true },

  // auth
  { route: '/auth', content: <AuthMailCode />, auth: false },
  { route: '/auth/next', content: <AuthNext />, auth: true },
  { route: '/auth/x', content: <AuthSessionX />, auth: true }
]

export default function RouteX () {
  const { user } = useAuth()
  // console.log (user)

  return (
    <Routes>
      {routes.map((item, i) =>
        item.auth ? (
          <Route
            key={i}
            path={item.route}
            element={!user ? <Navigate to='/' replace /> : item.content}
          />
        ) : (
          <Route key={i} path={item.route} element={item.content} />
        )
      )}
    </Routes>
  )
}
