import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { TransfersDataList } from '../../services/srvc-transfers-data-realm'
import { GetUserForm } from '../../services/srvc-utilities'
import { getDate } from '../../utils/utils'
import Table from '../common/Table'
import { DispalyLoder, StatusBadge } from '../common/utils'

export default function TransfersListModule (props) {
  // console.log(props.search)
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const header = ['user', 'created', 'status', 'view']

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const result = await TransfersDataList({
          data: {
            consumer: asset.item
          }
        })
        // console.log('result', result)
        if (result.stat) {
          const temp = result.data?.list?.sort((a, b) => b.created - a.created)
          const transformedItem = temp.map(item => {
            const { debt, memo, created, stat } = item
            return {
              user: `${debt?.name}` + `\n` + `${debt?.mail}` + `\n` + `${memo}`,
              created: getDate(created),
              status: <StatusBadge code={stat} />,
              view: <Link to={`/team/transfers/${item.item}`}>View</Link>,
              item: item.item
            }
          })
          setData({ header, body: transformedItem })
        }
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={'No Transfers'} />

  return <Table data={data} />
}
