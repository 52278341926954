import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'

import { FormatsList } from '../../services/srvc-formats-realm'

import { getDate } from '../../utils/utils'
import Table from '../common/Table'

import { DispalyLoder, StatusBadge } from '../common/utils'

export default function FormatsListConsumerModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate()
  
  const [loader, setLoader] = useState(true)
  
  const [data, setData] = useState()
  const [sort, setSort] = useState()

  // const header = ['name', 'memo', 'domain', 'created', 'status', 'view']
  const header = ['name', 'memo', 'created', 'status', 'view']

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const res = await FormatsList({
          data: {
            consumer: asset.item,
            index: 1,
            items: 25,
            filters: { name: {} }
          }
        })
        const temp = res.data?.list?.sort((a, b) => b.created - a.created)
        const transformedItem = temp.map(item => {
          const { name, memo, created, feat } = item
          return {
            name,
            memo,
            // domain: feat.sort,
            created: getDate(created),
            status: <StatusBadge code={feat?.live ? 6 : 0} />,
            view: <Link to={`/team/formats/${item.item}`}>View</Link>,
            item: item.item
          }
        })
        setData({ header, body: transformedItem })
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  if (loader) return <DispalyLoder />
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={'No Format Listed'} />

  return <Table data={data} />
}
