import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'
import { DispalyLoder } from '../common/utils'
import DownloadLink from '../common/DownloadLink'
import { getDate } from '../../utils/utils'

export default function TransfersActions (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [downloadProps, setDownloadProps] = useState()
  const { id } = useParams()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        setData(props)
        setLoader(false)
      }
      fetchData()
    }
  }, [props])

  useEffect(() => {
    //set download data
    if (data) {
      const cols = ['asset name', 'creator', 'number', 'media link', 'created']
      const rows = data.vars
        ?.map(item => Array.isArray(item.data) && item.data)
        ?.flat(1)
        ?.map(item => {
          return {
            'asset name': item?.asset?.name,
            creator: item?.creator?.name,
            number: item?.number,
            'media link': item?.media?.link,
            created: getDate(item.created)
          }
        })
      if (rows?.length > 0) {
        setDownloadProps({ rows, cols, filename: 'data.csv' })
      }
    }
  }, [data])

  if (loader) return <DispalyLoder />
  if (!loader && !data) return <DispalyLoder msg={'No Data'} />

  return (
    <>
      <div className='back-color-wite rounded-xd'>
        <div className={`p-1`}>
          <div
            className={`d-flex p-2 align-middle cursor hidark rounded-wd`}
            style={{ height: '2.4rem' }}
          >
            <div className=''>
              <i className={`m-0 p-0  `} style={{ fontSize: '1.5rem' }}></i>
            </div>
            <div className='ms-2'>
              <span className='text-small align-middle'>
                {(downloadProps && <DownloadLink download={downloadProps} />) ||
                  'No Data'}
              </span>
            </div>
            <div className='ms-auto '>
              <i
                className={`m-0 p-0 bx bx-chevron-right`}
                style={{ fontSize: '1.5rem' }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
