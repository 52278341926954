import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'
import { getDate } from '../../utils/utils'
import { DetailsPage, DispalyLoder, StatusBadge } from '../common/utils'
import WebbDividerSmall from '../webx/webb-divider-sm'

export default function FormatsViewSidebar (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [details, setDetails] = useState()

  const { id } = useParams()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        console.log(props)
        setLoader(true)
        setData(props)
        setLoader(false)
      }
      fetchData()
    }
  }, [props])

  useEffect(() => {
    if (asset) {
      const fetch = async () => {
        setDetails({
          name: data?.name,
          memo: data?.memo,
          created: getDate(data?.created),
          status: <StatusBadge code={data?.status?.live ? 6 : 0} />
        })
      }
      fetch()
    }
  }, [data])

  if (loader) return <DispalyLoder />
  if (!loader && !data) return <DispalyLoder msg={'No Data'} />

  return (
    <>
      <div className={'back-color-wite p-3 rounded-xd'}>
        <DetailsPage {...details} />
        
      </div>
    </>
  )
}
