import React from 'react'
import WebbDividerSmall from '../webx/webb-divider-sm'

export default function TaxxonCard ({
  item,
  name,
  memo,
  selected = false,
  handleFormat = () => {},
  isDisabled = false
}) {
  const classname = selected ? 'back-color-next text-color-wite' : ''
  return (
    <>
      <div
        className={`p-2 px-3 rounded-xd border h-100 cursor hidark ${classname}`}
        onClick={() => (!isDisabled ? handleFormat(item) : '')}
        isDisabled={isDisabled}
      >
        <p className='text-bold m-0'>{name}</p>
        <p className='text-small m-0 text-wd'>{memo}</p>
      </div>
      <WebbDividerSmall />
    </>
  )
}
