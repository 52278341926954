import BaseMedia from '../../data.media/tokenasset.png'
import { getDummyImg } from '../../utils/utils'
const excludeTaxxo = [
  '1435bad88b624449aa04734c500fc17d5',
  '221e45dd818c430e958c1c822311c8167'
] //age,bank stmt
export default function CredCards (item) {
  console.log('item', item)
  const media = excludeTaxxo.includes(item.taxxon)
    ? getDummyImg('200x200')
    : item?.media?.link || BaseMedia
  return (
    <div className='mb-2'>
      <div className='d-flex cursor justify-content-start'>
        <div className=''>
          <div className='' style={{ position: 'relative' }}>
            <img
              src={media}
              className='media rounded-wd border'
              style={{ width: '4.2rem', height: '4.2rem' }}
              alt={'...'}
            ></img>
            <div
              className=''
              style={{
                float: 'left',
                position: 'absolute',
                padding: '6px',
                left: '0px',
                top: '0px',
                zindex: '99'
              }}
            >
              {/* <i
                className={`text-lead ${
                  item?.select
                    ? 'bx bxs-check-circle text-color-success'
                    : 'bx bx-circle text-color-lite'
                }`}
              ></i> */}
            </div>
          </div>
        </div>

        <div className='ms-2'>
          <p className='text-bold m-0'>{item?.meta?.name || '****'}</p>
          {excludeTaxxo.includes(item.taxxon) && item?.data ? (
            <p className='m-0 mb-1'>{item?.data?.age || 'Bank Statement'}</p>
          ) : (
            <p className='m-0 mb-1'>{item?.number || '****'}</p>
          )}

          <p className='text-small text-color-tone m-0'>
            {item?.creator?.name || '****'}
          </p>
        </div>
      </div>
      <div className='border-bottom mt-2 mb-2'></div>
    </div>
  )
}
