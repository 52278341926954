import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { FormatsDetails } from '../../services/srvc-formats-realm'
import { GetUserForm } from '../../services/srvc-utilities'
import { DispalyLoder, FormDisplay } from '../common/utils'
import TaxxonCard from './TaxxonCard'

const list = [
  'Identity',
  'Finance',
  'Social',
  'Academics',
  '',
  'Assets',
  'Work',
  'Health'
]

export default function FormatsViewConsumerModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)

  const [data, setData] = useState()
  const [sort, setSort] = useState()
  const [taxxon, setTaxxon] = useState([])

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const res = await FormatsDetails({
          data: { item: id, consumer: asset.item }
        })

        if (res?.stat) {
          setData(res.data.vars)
          var sortx = [
            ...new Set(
              res.data.vars.map(x => {
                return x.code.substring(0, 1)
              })
            )
          ]
          setSort(
            Array.from(sortx, x => {
              return { code: x, name: list[x - 1] }
            })
          )

          const transformedVars = res.data?.vars.map(item => ({
            ...item,
            selected: true,
            isDisabled: true
          }))
          setTaxxon(transformedVars)
          props.handleData(res.data)
        }
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  if (loader) return <DispalyLoder />
  if (!loader && (!data || data.length === 0))
    return <DispalyLoder msg={'No Format Data'} />

  return (
    <>
      <p className='text-bold'>Selected Data Fields</p>

      <div className={''}>
        {sort &&
          sort.map((item, x) => (
            <div className='mb-3'>
              <p className='text-color-next text-bold mb-3'>{item.name}</p>
              <div className='row row-cols-1 row-cols-md-3'>
                {data &&
                  data.map((itemx, z) =>
                    itemx.code.substring(0, 1) === item.code ? (
                      <div className='col' key={z}>
                        {itemx.name}
                      </div>
                    ) : (
                      ''
                    )
                  )}
              </div>
              <div className='mb-3'></div>
              <div className='border-bottom'></div>
            </div>
          ))}
      </div>
    </>
  )
}
