import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { TransfersDataDetails } from '../../services/srvc-transfers-data-realm'
import { GetUserForm } from '../../services/srvc-utilities'
import { DispalyLoder, FormDisplay, STATUS_CODES } from '../common/utils'
import WebbDividerSmall from '../webx/webb-divider-sm'
import CredCards from './CredCards'

export default function TransfersViewConsumerModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [memo, setMemo] = useState()
  const { id } = useParams()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const res = await TransfersDataDetails({
          data: {
            item: id,
            consumer: asset.item
          }
        })

        if (res?.stat) {
          setData(res.data)
          const status = STATUS_CODES.find(
            status => status.code === res.data.stat
          )
          setMemo(`Transaction ${status.memo}`)
          props.handleData(res.data)
        }
        setLoader(false)
      }
      fetchData()
    }
  }, [])
  if (loader) return <DispalyLoder />
  if ((!loader && (!data || data.length === 0)) || data.stat != 6)
    return <DispalyLoder msg={memo || 'No Transfers Listed'} />

  return (
    <>
      <div className={'back-color-wite p-3 rounded-xd'}>
        <p className='text-normal m-0'></p>

        {data.vars.map(item => (
          <div key={item.taxxon}>
            <div className='mb-3'>
              <WebbDividerSmall />
              <p className='text-uppercase text-color-next m-0 mb-2'>
                {item.name}
              </p>
              <hr></hr>
            </div>
            {(Array.isArray(item.data) &&
              item.data.map((item, x) => (
                <CredCards {...item} key={item?.number || x} />
              ))) ||
              'No Data'}
          </div>
        ))}
      </div>
    </>
  )
}
